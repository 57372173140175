<template>
  <b-overlay :show="loading">
    <div>
      <b-card class="overflow-hidden" bg-variant="primary">
        <b-row no-gutters>
          <b-col md="6">
            <div style="padding: 10px">
              <h3 class="text-white">{{ data?.jadwal_name }}</h3>
              <span class="d-block text-white">Paket: {{ data?.name }}</span>
              <div class="d-flex align-items-center mt-2">
                <div class="d-flex align-items-center text-body mr-5">
                  <feather-icon icon="ClockIcon" class="mr-50 text-white" />
                  <small class="font-weight-bold text-white">Waktu Mulai</small>
                </div>
                <div class="d-flex align-items-center text-body ml-2">
                  <feather-icon icon="ClockIcon" class="mr-50 text-white" />
                  <small class="font-weight-bold text-white">
                    Waktu Berakhir
                  </small>
                </div>
              </div>
              <div class="d-flex align-items-center mt-1">
                <div class="d-flex align-items-center text-body">
                  <small
                    class="font-weight-bold text-white"
                    v-if="data?.info_start"
                  >
                    {{ getTanggal(data.info_start) }} WIB
                  </small>
                </div>
                <div class="d-flex align-items-center text-body ml-1">
                  <small
                    class="font-weight-bold text-white"
                    v-if="data?.info_end"
                  >
                    {{ getTanggal(data.info_end) }} WIB
                  </small>
                </div>
              </div>
              <div class="mt-3">
                <b-button
                  class="mt-1 mb-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="light"
                  @click="mulai"
                >
                  <span>Mulai Tryout</span>
                </b-button>
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <b-card>
              <b-row class="">
                <b-col>
                  <b-media vertical-align="top" class="mb-2">
                    <template #aside>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="dark"
                        class="btn-icon rounded-circle"
                        disabled
                      >
                        <feather-icon icon="UserIcon" />
                      </b-button>
                    </template>
                    <span>Peserta</span>
                    <br />
                    <b>{{ data.sum_quota }}/{{ data.max_quota }}</b>
                  </b-media>
                </b-col>
                <b-col>
                  <b-media vertical-align="top" class="mb-2">
                    <template #aside>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="dark"
                        class="btn-icon rounded-circle"
                        disabled
                      >
                        <feather-icon icon="ClockIcon" />
                      </b-button>
                    </template>
                    <span>Total Waktu</span>
                    <br />
                    <b>{{ totalTimer }} menit</b>
                  </b-media>
                </b-col>
                <b-col>
                  <b-media vertical-align="top" class="mb-2">
                    <template #aside>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="dark"
                        class="btn-icon rounded-circle"
                        disabled
                      >
                        <feather-icon icon="FileTextIcon" />
                      </b-button>
                    </template>
                    <span>Total Soal</span>
                    <br />
                    <b>{{ totalSoal }} Soal</b>
                  </b-media>
                </b-col>
              </b-row>
            </b-card>
            <!-- <b-button variant="light float-right"> <feather-icon icon="ClockIcon" /> <span class="text-muted"> Tryout Dimulai </span> <span class="text-danger"> 7 hari, 3 jam, 5 menit </span> </b-button> -->
          </b-col>
        </b-row>
      </b-card>
      <b-row class="mt-2">
        <b-col lg="6" cols="12">
          <b-card
            border-variant="primary"
            :header="'Subtest (' + getDataSubtest() + ')'"
            header-bg-variant="primary"
            header-text-variant="white"
          >
            <b-card-body>
              <app-collapse v-if="dataSubtes.length > 0">
                <div class="" v-for="(data, i) in dataSubtes" :key="i">
                  <app-collapse-item
                    :title="data.name"
                    v-if="data.name && data.items"
                  >
                    <ul style="padding: 0">
                      <li
                        v-for="(items, i) in data.items"
                        :key="i"
                        class="mt-1"
                        style="border-bottom: 3px solid #e83b07"
                      >
                        <b>{{ items.nama_ujian }}</b>
                        <br />
                        <section class="pb-1">
                          <feather-icon icon="ClockIcon" class="mr-50" />
                          <small class="font-weight-bold">
                            {{ items.timer }} Menit
                          </small>
                          <feather-icon
                            icon="FileTextIcon"
                            class="mr-50 ml-2"
                          />
                          <small class="font-weight-bold">
                            {{
                              items?.ujian_data?.sum_soal
                                ? items?.ujian_data?.sum_soal
                                : 0
                            }}
                            Soal
                          </small>
                        </section>
                      </li>
                    </ul>
                  </app-collapse-item>
                  <ul style="padding: 0" v-else>
                    <li style="border-bottom: 3px solid #e83b07">
                      <b>{{ data.nama_ujian }}</b>
                      <br />
                      <section class="pb-1">
                        <feather-icon icon="ClockIcon" class="mr-50" />
                        <small class="font-weight-bold">
                          {{ data.timer }} Menit
                        </small>
                        <feather-icon icon="FileTextIcon" class="mr-50 ml-2" />
                        <small class="font-weight-bold">
                          {{
                            data?.ujian_data?.sum_soal
                              ? data?.ujian_data?.sum_soal
                              : 0
                          }}
                          Soal
                        </small>
                      </section>
                    </li>
                  </ul>
                </div>
              </app-collapse>
              <div v-else>
                <h5 class="text-danger text-center mt-2">Tidak ada Subtest</h5>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col lg="6" cols="12">
          <b-card
            border-variant="primary"
            header="Berikut petunjuk pengerjaan"
            header-bg-variant="primary"
            header-text-variant="white"
          >
            <b-card-body>
              <b v-if="data.instruction" v-html="data.instruction"></b>
              <b v-else>
                <ol style="padding: 0">
                  <li>Tryout ini akan terbagi menjadi beberapa subtes</li>
                  <li>
                    Sesi dikerjakan sesuai urutan dan dimulai saat kamu menekan
                    tombol ‘Mulai’
                  </li>
                  <li>
                    Sesuai dengan peraturan resmi UTBK 2023, kamu hanya dapat
                    mengerjakan tiap komponen dalam durasi yang tersedia
                  </li>
                  <li>
                    Setelah mengerjakan semua subtes, pastikan klik tombol
                    “kumpulkan”
                  </li>
                </ol>
              </b>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCollapse,
  BButton,
  VBToggle,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardImg,
  BImg,
  BBadge,
  BRow,
  BCol,
  BMedia,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BOverlay,
    BButton,
    BCard,
    BCardHeader,
    BCollapse,
    BCardBody,
    BCardTitle,
    BCardImg,
    BImg,
    BBadge,
    BRow,
    BCol,
    BMedia,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  computed: {
    tryoutDetail() {
      return this.$store.state.tryout.detail;
    },
  },
  data() {
    return {
      totalTimer: 0,
      totalSoal: 0,
      data: {},
      dataSubtes: [],
      loading: false,
    };
  },
  methods: {
    getDataSubtest() {
      return this.dataSubtes.reduce((total, item) => {
        if (item.items) {
          total += item.items.length;
        } else {
          total += 1;
        }

        return total;
      }, 0);
    },
    getData() {
      this.loading = true;
      let id = this.tryoutDetail.id;

      let params = {
        id,
      };
      if (this.$route.query?.j) {
        params.jadwal_id = this.$route.query.j;
      }
      this.$store
        .dispatch("paketTryout/detail", params)
        .then((res) => {
          this.loading = false;
          this.data = res.data;
          this.$store.commit("paketTryout/SET_DETAIL", res.data);
          this.getSubtes();
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
          this.displayError(err);
        });
    },
    getTotalSoal(subtests) {
      let totalSoal = 0;
      subtests.forEach((item) => {
        if (item.items && item.items.length > 0) {
          const totalSoalSubtest = item.items.reduce((total, item) => {
            if (item?.ujian_data?.soal_ids) {
              const arraySoal = item?.ujian_data?.soal_ids.split(",");
              total += arraySoal.length;
            }
            return total;
          }, 0);
          totalSoal += totalSoalSubtest;
        } else {
          if (item?.ujian_data?.soal_ids) {
            const arraySoal = item?.ujian_data?.soal_ids.split(",");
            totalSoal += arraySoal.length;
          }
        }
      });

      return totalSoal;
    },
    getTotalTimer(subtests) {
      let totalTimer = 0;
      subtests.forEach((item) => {
        if (item.items && item.items.length > 0) {
          const totalTimerSubtest = item.items.reduce(
            (total, subtest) => (total += parseInt(subtest.timer)),
            0
          );
          totalTimer += totalTimerSubtest;
        } else {
          totalTimer += item.timer ? parseInt(item.timer) : 0;
        }
      });
      return totalTimer;
    },
    getSubtes() {
      let params = {
        paket_id: this.data.id,
      };

      if (this.$route.query?.j) {
        params.jadwal_id = this.$route.query?.j;
      }
      this.$store
        .dispatch("ujian/subtesPaket", params)
        .then((res) => {
          this.dataSubtes = res.data.data;
          const isPaketContainSubcat = this.data?.is_contain_subcat == 1;
          if (isPaketContainSubcat) {
            // filter hanya tampil yg subcat nya ada subtsetnya
            const filtered = this.dataSubtes.filter(
              (subcat) => subcat.items.length > 0
            );
            this.dataSubtes = filtered;
          }
          this.totalTimer = this.getTotalTimer(this.dataSubtes);
          this.totalSoal = this.getTotalSoal(this.dataSubtes);
        })
        .catch((err) => {
          this.displayError(err);
          return false;
        });
    },
    async mulai() {
      // check apakah paket ini is_contain_rumpun=1 (harus pilih dlu rumpun)
      // jika iya, check kalo belum pernah pilih rumpun dan jurusan, arahin ke page sana
      // Untuk SNBT dan Mandiri
      const mustChooseRumpun =
        this.data?.is_contain_rumpun == 1 &&
        ["utbk", "mandiri"].includes(this.data?.rasionalisasi_type);

      // tapi ada juga siswa yg harus pilih sekolah kedinasan
      // khusus untuk rasionalisasi bintara dan kedinasan
      const mustChooseSekolahKedinasan =
        this.data?.is_contain_rumpun == 1 &&
        ["kedinasan", "bintara"].includes(this.data?.rasionalisasi_type);

      if (mustChooseRumpun) {
        // check apakah sudah pilih rumpun dan jurusan
        try {
          this.loading = true;
          const params = {
            paket_id: this.data?.id,
            user_id: this.user?.id,
            jadwal_id: this.$route.query?.j,
          };
          let selectedRumpuns = await this.$store.dispatch(
            "rumpun/rumpunPaket",
            params
          );
          selectedRumpuns = selectedRumpuns.data?.data;
          this.$store.commit("tryout/SET_SELECTED_RUMPUN", selectedRumpuns);

          // check selected jurusan
          const paramsMajor = {
            paket_id: this.data?.id,
            user_id: this.user?.id,
            jadwal_id: this.$route.query?.j,
          };
          let selectedMajors = await this.$store.dispatch(
            "jurusan/jurusanPaket",
            paramsMajor
          );
          selectedMajors = selectedMajors.data?.data;
          this.$store.commit("tryout/SET_SELECTED_MAJOR", selectedMajors);

          const isFirstTryout =
            selectedRumpuns.length < 1 || selectedMajors.length < 1;
          this.loading = false;
          if (isFirstTryout) {
            // redirect untuk memilih
            this.$router.push(`/pilih-rumpun?j=${this.$route.query.j}`);
          } else {
            // redirect ke detail tryout
            let url = "/tryout-detail-subtest";
            if (this.$route.query?.j) {
              url += `?j=${this.$route.query?.j}`;
            }
            window.location = url;
          }
        } catch (e) {
          this.loading = false;
          this.displayError(e);
          return false;
        }
      } else if (mustChooseSekolahKedinasan) {
        // check apakah sudah pilih sekolah kedinasannya
        // check selected sekolah
        const params = {
          paket_id: this.data?.id,
          user_id: this.user?.id,
          jadwal_id: this.$route.query?.j,
        };

        try {
          this.loading = true;
          let selectedSchool = await this.$store.dispatch(
            "jurusan/jurusanPaket",
            params
          );
          selectedSchool = selectedSchool.data?.data;
          this.$store.commit("tryout/SET_SELECTED_SCHOOL", selectedSchool);
          this.loading = false;
          const firstTryout = selectedSchool.length < 1;
          if (firstTryout) {
            // redirect untuk memilih sekolah kedinasan
            this.$router.push(`/pilih-sekolah?j=${this.$route.query.j}`);
          } else {
            // redirect ke detail tryout
            let url = "/tryout-detail-subtest";
            if (this.$route.query?.j) {
              url += `?j=${this.$route.query?.j}`;
            }
            window.location = url;
          }
        } catch (e) {
          this.loading = false;
          this.displayError(e);
          return false;
        }
      } else {
        // langsung ke detail subtest untuk pengerjaan
        let url = "/tryout-detail-subtest";
        if (this.$route.query?.j) {
          url += `?j=${this.$route.query?.j}`;
        }
        window.location = url;
      }
    },
  },
  created() {
    if (this.tryoutDetail.name == undefined || !this.$route.query.j) {
      this.$router.push("/tryout");
    }
    this.getData();
  },
};
</script>
